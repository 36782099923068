
import { defineComponent } from 'vue';
import {
  alertController,
  IonPage,
  IonContent,
  IonImg,
  IonToolbar,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
  IonFooter,
} from '@ionic/vue';
import axios from 'axios';
import store from '@/store';
import { getCookie } from '@/utils/methods/cookieMethods';
import { validateUser, setUserInfo } from '@/utils/methods/userMethods';
import { SingleShop } from '@/types/shopTypes';
import { openInAppBrowser } from '@/utils/methods/capacitorMethods';
import { openToastOptions } from '@/utils/ionCustomHelpers';
import { ToastColor } from '@/types/ionicTypes';
import { tblVars } from '@/variables/environmentVariables';

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonImg,
    IonToolbar,
    IonButtons,
    IonButton,
    IonBackButton,
    IonIcon,
    IonFooter,
  },
  data() {
    return {
      currentSlug: this.$route.params.slug,
      singleShopData: {} as SingleShop,
      singleShopGallery: '',
      galleryImages: [] as Array<string>,
      isFavorite: false,
      tblVars,
      webShareError: '',
    };
  },
  computed: {
    userLoggedIn: () => {
      return store.getters.getLoginState;
    },
    userFavoriteShopIds: () => {
      return store.getters.getUserFavoriteShops;
    },
    shopMapsLink(): string | null {
      if (this.singleShopData.street && this.singleShopData.city && this.singleShopData.zip) {
        return `https://www.google.ch/maps/search/${encodeURIComponent(
          this.singleShopData.street + ', ' + this.singleShopData.zip + ' ' + this.singleShopData.city
        )}/`;
      } else if (this.singleShopData.location_lat && this.singleShopData.location_lon) {
        return `https://www.google.ch/maps/search/${encodeURIComponent(
          this.singleShopData.location_lat + ',' + this.singleShopData.location_lon
        )}/`;
      } else if (this.singleShopData.title) {
        return `https://www.google.ch/maps/search/${encodeURIComponent(this.singleShopData.title)}/`;
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.fetchShop(this.currentSlug);
  },
  methods: {
    openInAppBrowser,
    // async shareTest(title: string, url: string) {
    //   // navigator.clipboard.write(data).then(
    //   //   function () {
    //   //   console.log('success');
    //   //   },
    //   //   function () {
    //   //   console.error('fail');
    //   //   }
    //   // );
    //   const shareData = {
    //     text: 'eatable – Nachhaltiger Shop',
    //     text: title,
    //     url: url,
    //   };

    //   try {
    //     navigator.share(shareData);
    //     console.log(shareData);
    //   } catch(err) {
    //     console.log('Error: ' + err);
    //     this.webShareError = 'Error: ' + err;
    //   }
    // },
    fetchShop(slug: string | string[]) {
      axios
        .post(`${tblVars.baseApiUrl}/index.php`, {
          action: 'getBySlug',
          slug: slug,
          table: 'shops',
        })
        .then(response => {
          if (response.data && typeof response.data !== 'string') {
            if (response.data.gallery !== null) {
              this.galleryImages = response.data.gallery.match(/gallery_eatable-farms_[0-9]+.[a-z]+/gm);
            }
            this.singleShopData = response.data;
          } else if (typeof slug === 'string' && !isNaN(parseInt(slug))) {
            // adding support for shop id as slugs
            axios
              .post(`${tblVars.baseApiUrl}/index.php`, {
                action: 'getSingleShop',
                shopId: slug,
              })
              .then(response => {
                if (response.data && typeof response.data !== 'string') {
                  if (response.data.gallery !== null) {
                    this.galleryImages = response.data.gallery.match(/gallery_eatable-farms_[0-9]+.[a-z]+/gm);
                  }
                  this.singleShopData = response.data;
                } else {
                  this.$router.push('/404?source=shop');
                }
              })
              .catch(error => {
                console.error(error);
              });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    favoriteShopToggle(event: any) {
      event.stopPropagation();
      if (!this.userLoggedIn) {
        this.userLoginPrompt();
      }
      const sessionToken = getCookie('sessionToken');
      if (!sessionToken) {
        console.log(sessionToken);
        return;
      }

      validateUser(sessionToken).then(userId => {
        let ajaxAction;
        let toastTitle = 'Zur Sammlung hinzugefügt';
        let toastColor: ToastColor = 'success';
        if (this.userFavoriteShopIds.includes(parseInt(this.singleShopData.id))) {
          ajaxAction = 'removeUserFavoriteShop';
          toastTitle = 'Aus deiner Sammlung entfernt';
          toastColor = 'tertiary';
        } else {
          ajaxAction = 'addUserFavoriteShop';
        }
        axios
          .post(`${tblVars.baseApiUrl}/index.php`, {
            headers: {
              authorization: sessionToken,
            },
            action: ajaxAction,
            userId: userId,
            favoriteShop: this.singleShopData.id,
          })
          .then(() => {
            setUserInfo(sessionToken, userId);
            openToastOptions(toastTitle, toastColor, 2000, 'top');
          })
          .catch(error => {
            console.error(error);
          });
      });
    },
    async userLoginPrompt() {
      const alert = await alertController.create({
        header: 'Es freut uns, dass dir dieser Shop gefällt!',
        message: 'Melde dich an, um ihn in deiner Sammlung zu speichern...',
        buttons: [
          {
            text: 'Konto erstellen',
            role: 'register',
            handler: () => this.$router.push('/profil/registrierung'),
          },
          {
            text: 'zum Login',
            role: 'login',
            handler: () => this.$router.push('/profil/login'),
          },
          {
            text: 'abbrechen',
            role: 'cancel',
          },
        ],
      });
      return alert.present();
    },
  },
});
